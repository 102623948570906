<template>
  <div>
    <loader
      v-if="show_overlay"
      object="#9658cc"
      color1="#532a76"
      color2="#d3bae9"
      size="5"
      speed="2"
      bg="#343a40"
      objectbg="#999793"
      opacity="30"
      name="circular"
    />
    <!-- <this-header /> -->
    <div class="container-sm-lot pl-1 pr-1">
      <div
        v-if="step === 1"
        class="top-bxt my-1"
      >
        <img
          src="/icon/back.svg"
          alt="back"
          class="cursor-pointer ts-left"
          @click="$router.go(-1)"
        >

        <h3 class="text-primary mb-0">
          ระบุจำนวนเงิน
        </h3>
      </div>

      <div
        v-if="step === 2"
        class="top-bxt my-1"
      >
        <!-- <img
          src="/icon/back.svg"
          alt="back"
          class="cursor-pointer ts-left"
          @click="step = 1"
        > -->

        <h3 class="text-primary mb-0">
          คัดลอกบัญชีธนาคาร
        </h3>
      </div>

      <!-- <b-overlay
        :show="show_overlay"
        variant="transparent"
        opacity="0.99"
        blur="5px"
        rounded="sm"
      >
        <template #overlay>
          <div class="text-center">
            <p id="cancel-label">
              กรุณารอสักครู่...
            </p>
          </div>
        </template> -->
      <div>
        <div v-if="step == 1">
          <div class="input-box-group mt-1">
            <img
              src="/₩.png"
              height="35"
            >
            <input
              v-model="amount"
              type="tel"
              class="form-withdraw w-100"
              placeholder="ระบุจำนวนเงิน"
            >
          </div>

          <!-- <div class="grid-balance mt-1">
              <div class="grid-item-balance">
                <button
                  class="btn-balance"
                  @click="amount += 1000"
                >
                  1,000
                </button>
              </div>

              <div class="grid-item-balance">
                <button
                  class="btn-balance"
                  @click="amount += 3000"
                >
                  3,000
                </button>
              </div>

              <div class="grid-item-balance">
                <button
                  class="btn-balance"
                  @click="amount += 5000"
                >
                  5,000
                </button>
              </div>

              <div class="grid-item-balance">
                <button
                  class="btn-balance"
                  @click="amount += 7000"
                >
                  7,000
                </button>
              </div>

              <div class="grid-item-balance">
                <button
                  class="btn-balance"
                  @click="amount += 9000"
                >
                  9,000
                </button>
              </div>
            </div> -->

          <div class="grid-balance my-1">
            <div class="grid-item-balance">
              <button
                class="btn-balance"
                @click="amount = 30000 + Number(amount)"
              >
                30K
              </button>
            </div>

            <div class="grid-item-balance">
              <button
                class="btn-balance"
                @click="amount = 50000 + Number(amount)"
              >
                50K
              </button>
            </div>

            <div class="grid-item-balance">
              <button
                class="btn-balance"
                @click="amount = 100000 + Number(amount)"
              >
                100K
              </button>
            </div>

            <div class="grid-item-balance">
              <button
                class="btn-balance"
                @click="amount = 200000 + Number(amount)"
              >
                200K
              </button>
            </div>

            <div class="grid-item-balance">
              <button
                class="btn-balance"
                @click="amount = 500000 + Number(amount)"
              >
                500K
              </button>
            </div>
          </div>
          <div class="mt-1 text-center">
            <button
              class="btn-gray-txt p-25 w-50"
              @click="StorePreDep"
            >
              ถัดไป
            </button>
          </div>
        </div>

        <div v-if="step == 2">
          <div class="card-list p-puple mt-50 border-0 mb-1">
            <div class="d-flex justify-content-start align-items-center">
              <img
                src="/bank/081.jpg"
                alt="card"
                height="80"
                class="mr-2 rounded"
              >
              <div>
                <p class="mb-0 text-white">
                  Hana Bank
                </p>

                <h3 class="mb-0 txt-sh">
                  {{ bank.Accno }}
                </h3>

                <p class="mb-0 text-white">
                  {{ bank.Fullname }}
                </p>
              </div>
            </div>

            <button
              v-clipboard:copy="bank.Accno"
              v-clipboard:success="onCopy"
              v-clipboard:error="onError"
              class="btn-clipboard-deposit"
            >
              <i class="fal fa-copy mr-25" /> คัดลอก
            </button>

            <!-- <button
                v-clipboard:copy="bank.Accno"
                v-clipboard:success="onCopy"
                v-clipboard:error="onError"
                class="mb-0 btn-copy-hana"
              >
                <i class="fal fa-copy" />
              </button> -->
          </div>

          <div class="mt-2 text-center">
            <div class="card-time mt-2">
              <h5 class="text-primary mb-0">
                ยอดเงินที่ต้องโอน
              </h5>
              <h2 class="text-success mb-0">
                {{ Commas(predep.amount) }} <small>₩</small>
              </h2>
              <small class="text-primary">{{
                FormatDate(predep.created_at)
              }}</small>
            </div>

            <p class="mb-0 mt-2">
              กรุณาโอนเงินภายในเวลาที่กำหนด
            </p>

            <h5 class="p-time text-warning">
              {{ dep_timeout }}
            </h5>
          </div>
          <!-- <div class="mt-1">
              <button
                class="btn-golden-radius"
                @click="ConfirmDep"
              >
                โอนเรียบร้อย
              </button>
            </div> -->
          <div class="mt-1 text-center">
            <button
              class="btn-cancel"
              @click="CancelDep"
            >
              ยกเลิก
            </button>
          </div>

          <div class="text-center my-1">
            <small class="text-muted my-font">
              ระบบจะทำการเติมเข้าให้ทันอัตโนมัติ รอทำรายการ 5-10 วินาที
            </small>
          </div>
        </div>
      </div>
      <!-- </b-overlay> -->
    </div>

    <!-- modal warning -->
    <b-modal
      ref="my-warning"
      hide-footer
      hide-header
      size="xs"
      centered
    >
      <div class="text-center">
        <div class="closed">
          <i
            class="far fa-times cursor-pointer icon-close"
            @click="$refs['my-warning'].hide()"
          />
        </div>

        <div class="py-1">
          <i
            class="fas fa-exclamation-triangle fa-3x"
            style="color: #BFBFBF;"
          />

          <p class="mb-0 text-primary mt-1">
            กรุณา“โอนตรงตามจำนวน”ที่แจ้งทุกหลัก
          </p>

          <p class="mb-0 text-primary">
            เพื่อความรวดเร็วในการดำเนินการ
          </p>
        </div>

      </div>
    </b-modal>

    <b-modal
      ref="my-timeout"
      hide-footer
      hide-header
      centered
    >
      <div class="text-center">
        <div class="text2">
          ฝากเงิน
        </div>

        <div class="closed">
          <i
            class="fas fa-times fa-2x text-white cursor-pointer"
            @click="$refs['my-welcome'].hide()"
          />
        </div>
        <div>
          <img
            src="/icon/sobad.svg"
            alt="welcome"
            width="95"
          >
        </div>

        <p class="mb-0 text-white">
          หมดเวลาการทำรายการ
        </p>

        <button
          class="btn-golden-radius mt-2 mb-1 w-md-50"
          @click="$refs['my-welcome'].hide(), $router.push({ name: 'login' })"
        >
          ตกลง
        </button>
      </div>
    </b-modal>

    <ThisFooter />
    <!-- </div> -->
  </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import ThisHeader from '../components/ThisHeader.vue'
// import { BOverlay } from 'bootstrap-vue'
import moment from 'moment-timezone'
import ThisFooter from '../components/ThisFooter.vue'
// import { t } from '@/@core/libs/i18n/utils'

export default {
  name: 'Home',
  components: {
    // ThisHeader,
    ThisFooter,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    // BOverlay,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      amount: 0,
      bank: {
        Accno: '',
        Fullname: '',
      },
      step: 1,
      show_overlay: false,
      predep: {},
      dep_interval: null,
      dep_timeout: null,
      currentRouteName: '',
      StatusCheck: true,
    }
  },
  created() {
    if (!this.userData) {
      this.$router.push({ name: 'login' })
    }
  },
  destroyed() {
    this.stopInterval()
  },
  mounted() {
    this.stopInterval()
    this.GetBank()
    this.GetPredep()
    this.$router.afterEach(this.updateRouteInfo)
    this.updateRouteInfo()
  },
  methods: {
    updateRouteInfo() {
      this.currentRouteName = this.$route.name
    },
    async GetBank() {
      this.show_overlay = true
      try {
        const { data: res } = await this.$http.get('/bank/dep')
        if (res.success) {
          this.bank = res.message
        }
      } catch (error) {
        console.log(error)
      }
    },
    async GetPredep() {
      try {
        const { data: res } = await this.$http.get('/hana/predeposit/show')
        if (res.success) {
          this.predep = res.data
          this.WaitingConfirm()
          this.step = 2
        } else {
          this.show_overlay = false
        }
        // console.log(this.bank)
      } catch (error) {
        console.log(error)
        this.show_overlay = false
      }
    },
    async StorePreDep() {
      try {
        if (Number(this.amount) > 0) {
          this.show_overlay = true
          const obj = {
            amount: Number(this.amount),
          }
          const { data: res } = await this.$http.post('/hana/predeposit', obj)
          this.predep = res
          this.WaitingConfirm()
          this.step = 2
          this.show_overlay = false
          this.$refs['my-warning'].show()
        }
      } catch (error) {
        console.log(error)
        this.show_overlay = false
      }
    },
    async WaitingConfirm() {
      try {
        this.stopInterval()
        if (this.CalDifTime(this.predep.created_at) !== 0) {
          this.dep_timeout = this.CalDifTime(this.predep.created_at)
          this.startInterval()
          this.show_overlay = false
        } else {
          this.stopInterval()
          this.CancelDep()
          if (this.dep_interval !== null) {
            this.$swal('หมดเวลา', '', 'error')
          }
          // this.$refs['my-timeout'].show()
          this.step = 1
        }
      } catch (error) {
        console.log(error)
      }
    },
    startInterval() {
      if (this.dep_interval === null) {
        this.dep_interval = setInterval(() => {
          if (this.currentRouteName !== 'bank') {
            this.stopInterval()
          } else {
            this.dep_timeout = this.CalDifTime(this.predep.created_at)
            if (this.StatusCheck) {
              this.CheckPredep()
            }
            if (this.dep_timeout === 0) {
              this.stopInterval()
              this.CancelDep()
              if (this.dep_interval !== null) {
                this.$swal('หมดเวลา', '', 'error')
              }
              // this.$refs['my-timeout'].show()
            }
          }
        }, 1000)
      }
    },
    async CheckPredep() {
      try {
        this.StatusCheck = false
        const StartTime = moment().tz('Asia/Bangkok')
        const params = {
          // eslint-disable-next-line no-underscore-dangle
          ID: this.predep._id,
        }
        const { data: res } = await this.$http.get('/hana/predeposit/check', {
          params,
        })
        const EndTime = moment().tz('Asia/Bangkok')
        if (res.success) {
          this.stopInterval()
          this.$router.push({ name: 'profile' })
        }
        const duration = moment.duration(EndTime.diff(StartTime))
        const Diff = duration.asMilliseconds()
        if (Diff < 2000) {
          const Sleep = 2000 - Diff
          setTimeout(() => {
            this.StatusCheck = true
          }, Sleep)
        } else {
          this.StatusCheck = true
        }
      } catch (error) {
        console.log(error)
        this.StatusCheck = true
      }
    },
    stopInterval() {
      if (this.dep_interval !== null) {
        clearInterval(this.dep_interval)
        this.dep_interval = null
      }
    },
    async ConfirmDep() {
      try {
        this.show_overlay = true
        const obj = {
          // eslint-disable-next-line no-underscore-dangle
          ID: this.predep._id,
        }
        await this.$http.post('/hana/predeposit/confirm', obj)
        this.show_overlay = false
        this.$router.push({ name: 'profile' })
      } catch (error) {
        console.log(error)
        this.show_overlay = false
      }
    },
    async CancelDep() {
      try {
        this.show_overlay = true
        const obj = {
          // eslint-disable-next-line no-underscore-dangle
          ID: this.predep._id,
        }
        await this.$http.post('/hana/predeposit/cancel', obj)
        this.show_overlay = false
        this.step = 1
        // this.$router.push({ name: 'profile' })
      } catch (error) {
        console.log(error)
        this.show_overlay = false
      }
    },
    CalDifTime(val) {
      const targetDate = moment(val).tz('Asia/Bangkok').add(10, 'minutes')
      const currentDate = moment().tz('Asia/Bangkok')
      const duration = moment.duration(targetDate.diff(currentDate))

      const minutes = duration.minutes()
      const seconds = duration.seconds()
      if (minutes <= 0 && seconds < 1) {
        return 0
      }
      return `${minutes}m ${seconds}s`
    },
    FormatDate(val) {
      return moment(val).tz('Asia/Bangkok').format('DD/MM/YYYY HH:mm:ss')
    },
    Commas(x) {
      if (!x) {
        return 0
      }
      if (x % 1 !== 0) {
        return Number(x)
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    onCopy() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'คัดลอกหมายเลขบัญชีสำเร็จ!',
          icon: 'CopyIcon',
        },
      })
    },
    onError() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Failed to copy texts!',
          icon: 'BellIcon',
        },
      })
    },
  },
}
</script>

  <style scoped>
</style>
